import { Grid, Stack, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid'
import { createRef, useEffect } from 'react'
import { useConfig } from '@kikoda/generated-config-hooks'
import { AppConfig } from 'hooks/useAppConfig'

export interface ProviderPaymentProps {}

export const ProviderPayment: React.FC<ProviderPaymentProps> = () => {
  const { state }: any = useLocation()
  const src = useConfig<AppConfig>().additionalConfig?.hppUrl;
  const formRef = createRef<HTMLFormElement>()

  useEffect(() => {
    formRef.current?.submit()
  }, [formRef])
  return (
    <Stack spacing={5} p={5}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid sx={{ width: '45%' }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#4f4f4f"
            alignSelf="flex-start"
          >
            Order Details
          </Typography>

          <DataGrid
            sx={{ width: '100%', minHeight: '266px' }}
            hideFooter
            columns={[
              {
                field: 'license',
                headerName: 'License Number',
                flex: 1,
              },
              {
                field: 'name',
                headerName: 'Name',
                flex: 1,
              },
              {
                field: 'amount',
                headerName: 'Amount',
                flex: 1,
              },
            ]}
            rows={state.details.map((x, i) => ({
              id: i,
              license: x.license,
              name: x.firstName + ' ' + x.lastName,
              amount: x.amount,
            }))}
          />
        </Grid>
      </Grid>
      <iframe
        style={{ marginTop: '0' }}
        id="hpp"
        name="hpp"
        title="hpp"
        frameBorder="0"
        width="100%"
        height={800}
      ></iframe>
      <form target="hpp" action={src} method="POST" ref={formRef}>
        <input type="hidden" name="token" value={state.token} />
      </form>
    </Stack>
  )
}
