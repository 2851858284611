import React from 'react'
import {
  Chip,
  Grid,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import {
  GridCellParams,
  GridColumns,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModel,
  useGridApiContext,
} from '@mui/x-data-grid'
import { DataGrid } from './DataGrid'
import { NumberInput } from 'components/Elements/Inputs/NumberInput'
import { IconButton } from 'components/Elements/Buttons/IconButton'
import { DeleteOutlined, Edit } from '@mui/icons-material'
import { NoRowsOverlay } from 'components/Elements/Buttons/NoRowsOverlay'

const CustomDataGrid = styled(DataGrid)(() => ({
  '.MuiDataGrid-main': {
    ' .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    ' .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
}))

export interface PhysicianSearchGridProps {
  rows: Array<PaymentItem & { isNew?: boolean }>
  setRows: React.Dispatch<
    React.SetStateAction<Array<PaymentItem & { isNew?: boolean }>>
  >
  handleDeleteClick: (id: any) => void
  disableEditAndRemove?: boolean
}

export interface PhysicianResult {
  firstName: string
  lastName: string
  license: string
  balance: string
  id: string
  statusId: number
  legalCanPay: boolean
}

export interface PaymentItem extends PhysicianResult {
  amount: string | number
}

const ErrorToolTip = styled(({ className, open, ...props }: TooltipProps) => (
  <Tooltip open={open} {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#d32f2f',
    maxWidth: 120,
    fontSize: 12,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#d32f2f',
  },
}))

const InfoToolTip = styled(({ className, open, ...props }: TooltipProps) => (
  <Tooltip open={open} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffb880',
    color: '#4f4f4f',
    fontSize: 15,
    padding: '10px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ffb880',
  },
}))

const BalanceToolTip = (props) => {
  if (props.color === 'error') return <ErrorToolTip {...props} />
  return <InfoToolTip {...props} />
}

export const PhysicianSearchGrid: React.FC<PhysicianSearchGridProps> = ({
  rows,
  setRows,
  handleDeleteClick,
  disableEditAndRemove,
}) => {
  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow: any = { ...newRow, isNew: false }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const EditCell = (props: GridRenderEditCellParams) => {
    const apiContext = useGridApiContext()
    const { id, value, field } = props
    const handleValueChange = (event: any) => {
      const newValue = event.target.value // The new value entered by the user
      apiContext.current.setEditCellValue({ id, field, value: newValue })
    }
    let message = 'Amount must be between $0.00 and the balance due.'
    const legalPayment = props.row.statusId === 6 && props.row.legalCanPay
    if (legalPayment) {
      message =
        'Please contact Brewton and Plante, PA at (850) 222-7718 to obtain your balance.'
    }
    return (
      <BalanceToolTip
        open={legalPayment ? true : props.error}
        title={message}
        arrow
        placement={legalPayment ? 'bottom' : 'right'}
        color={legalPayment ? 'info' : 'error'}
      >
        <NumberInput
          allowNegative={false}
          decimalScale={2}
          color="primary"
          size="medium"
          variant="outlined"
          fullWidth
          value={value}
          fixedDecimalScale
          onChange={handleValueChange}
          error={props.error}
        />
      </BalanceToolTip>
    )
  }

  const AmountCell = (params: GridRenderCellParams) => {
    const apiRef = useGridApiContext()

    return (
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body2">
            ${parseFloat(params.value).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            disabled={disableEditAndRemove}
            size="small"
            color="default"
            onClick={() => {
              apiRef.current.startCellEditMode({
                id: params.id,
                field: 'amount',
              })
              apiRef.current.setCellFocus(params.id, 'amount')
            }}
          >
            <Edit />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const columns: GridColumns<any> = [
    {
      field: 'license',
      headerName: 'License Number',
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
    },

    {
      field: 'balance',
      headerName: 'Balance Due',
      flex: 1,
      renderCell: (params) => {
        if (params.value === 'legal') {
          return <Chip label="Under Legal Review" />
        }
        return <>{`$${parseFloat(params.value).toFixed(2)}`}</>
      },
    },
    {
      field: 'amount',
      headerName: 'Amount to Pay',
      flex: 1,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const balance = parseFloat(params.row.balance)
        const amount = parseFloat(params.props.value)
        const error =
          (amount > balance &&
            params.row.statusId === 6 &&
            !params.row.legalCanPay) ||
          amount <= 0 ||
          isNaN(amount)
        return { ...params.props, error }
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <EditCell {...params} />
      ),
      renderCell: (params: GridRenderCellParams) => {
        return <AmountCell {...params} />
      },
    },
    {
      field: 'remove',
      headerName: '',
      flex: 0.5,
      renderCell: (params: GridCellParams) => (
        <IconButton
          disabled={disableEditAndRemove}
          sx={{ margin: 'auto' }}
          size="medium"
          color="error"
          onClick={(event) => {
            event.stopPropagation()
            handleDeleteClick(params.row.id)
          }}
        >
          <DeleteOutlined />
        </IconButton>
      ),
    },
  ]

  return (
    <div style={{ height: '35vh', width: '100%' }}>
      <CustomDataGrid
        onCellDoubleClick={(_params, event) => {
          event.stopPropagation()
        }}
        components={{ NoRowsOverlay: NoRowsOverlay }}
        rowHeight={60}
        disableColumnMenu
        disableSelectionOnClick
        pageSize={10}
        rows={rows}
        processRowUpdate={processRowUpdate}
        columns={columns}
        editMode="cell"
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  )
}
