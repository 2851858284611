import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material'
import { Button } from '../Buttons/Button'
import { PaymentItem } from 'components/Modules/DataDisplay/PhysicianSearchGrid'
import { DataGrid } from '@mui/x-data-grid'
import { usePaymentPageToken } from 'api/usePaymentPageToken'
import { TextBox } from '../Typography/TextBox'

export interface ConfirmPaymentDialogProps {
  open: boolean
  handleClose: () => void
  handleConfirm: (token: string) => void
  items: PaymentItem[]
}

function formatToUSD(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
}

export const ConfirmPaymentDialog = ({
  open,
  handleClose,
  handleConfirm,
  items,
}: ConfirmPaymentDialogProps) => {
  const {data} = usePaymentPageToken({ items: items.map(x => ({
      name: x.firstName + ' ' + x.lastName,
      amount: (+x.amount) as unknown as number,
      licenseNumber: x.license
    }))
  })
const otherdata = data as unknown as { token: string }
console.log(!otherdata?.token)
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {'Please confirm your selected provider information'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{mb:2}}>
            Please verify that the following provider information is correct
            before proceeding with your payment. If the information is incorrect,
            please return to the previous screen and select the correct individual
            from the Search Physician or License dropdown.
          </DialogContentText>          
          <TextBox sx={{mb: 2}}>
            <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1} sx={{ textDecoration: 'underline' }}>
              PLEASE READ - IMPORTANT NOTICES:
            </Typography>
            <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1}>
              - Do not pay if you are exempt. If you are unsure if you qualify for an exemption, please visit: <Link href="https://nica.com/medical-providers/exemptions/#documents-required" target='_BLANK'>Exemptions</Link>. 
            </Typography>
            <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1}>
              - If you have been instructed to deduct <Typography display='inline' fontWeight="bold" variant="body1" color="#4f4f4f" sx={{ textDecoration: 'underline'}}>INTEREST</Typography> from your payment, the amount can only be edited on this screen.
              If you fail to deduct the proper interest amount, NICA <Typography display='inline' fontWeight="bold" variant="body1" color="#4f4f4f" sx={{ textDecoration: 'underline'}}>CANNOT</Typography> refund it.
            </Typography>
          </TextBox>
          <Box sx={{ height: 300, width: '100%' }}>
            <DataGrid
              columns={[
                { field: 'license', headerName: 'License Number', flex: .5 },
                { field: 'firstName', headerName: 'First Name', flex: 1 },
                { field: 'lastName', headerName: 'Last Name', flex: 1 },
                { field: 'amount', headerName: 'Amount', flex: .5, valueFormatter: (params) => formatToUSD(params.value)}
              ]}
              rows={items}
              isRowSelectable={() => false} />
            <TextBox sx={{mt: 2}}><Typography align='right' variant="h5">Total Amount: {formatToUSD(items.reduce((a, b) => a + Number(b.amount), 0))}</Typography></TextBox>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            variant="outlined"
            color="warning"
            onClick={handleClose}
          >
            Go Back
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => handleConfirm(otherdata?.token ?? '')}
            disabled={!otherdata?.token}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
