import React from 'react'
import { CircularProgress } from '@mui/material'

import { PhysicianResult } from 'components/Modules/DataDisplay/PhysicianSearchGrid'
import { PhysicianSelectOption } from './PhysicianSelectOption'
import { Input } from './Input'
import { Select } from './Select'

export interface PhysicianSelectProps {
  selectedIds: Array<string>
  open?: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  results: any // fix type
  loading?: boolean
  handleClick: (item: PhysicianResult) => void
  disabled?: boolean
}

export const PhysicianSelect: React.FC<PhysicianSelectProps> = ({
  selectedIds: ids,
  searchTerm,
  setSearchTerm,
  results,
  loading,
  handleClick,
  disabled,
  setOpen,
  open,
}) => {
  return (
    <Select
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
      disabled={disabled}
      loading={loading}
      loadingText={'Searching...'}
      noOptionsText={'No results.'}
      filterOptions={(x) => x} // This is to remove default filtering
      inputValue={searchTerm}
      onInputChange={(event: any) => {
        event && setSearchTerm(event.currentTarget.value)
      }}
      getOptionDisabled={(option: PhysicianResult) => {
        return ids.includes(option.id)
      }}
      fullWidth
      options={results}
      renderInput={(params: any) => (
        <Input
          label="Search Physician Name or License"
          color="primary"
          size="medium"
          variant="outlined"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props: object, option: any, _state: object) => (
        <PhysicianSelectOption
          addedToGrid={props['aria-disabled']} // Items are only disabled if they have been added or have 0.00 balance
          zeroBalance={option.balance === '0.0000'}
          legalStatus={option.statusId === 6 && !option.legalCanPay} // 6 represents legal status
          pendingPaymentStatus={option.pendingPaymentStatus}
          key={option.id}
          option={option}
          onClick={handleClick}
        />
      )}
      getOptionLabel={(option: any) => option.id}
    />
  )
}
