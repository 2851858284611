import React, { useEffect } from 'react';

export const RedirectPage = ({to}:{to?: string}) => {
    useEffect(() => {
        //@ts-ignore
        window.top.location.href='completed';
    })
    return (<div>Redirecting...</div>);
}

export default RedirectPage;