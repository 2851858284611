import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { ProviderPayment } from 'pages/ProviderPayment'

import { ProviderSearch } from 'pages/ProviderSearch'
import {RedirectPage} from 'pages/RedirectPage'

function App() {
  return (
    <>
      <Helmet>
        <title>Provider Payment Portal</title>
      </Helmet>
      <Routes>
        <Route path="*" element={<Navigate to={'/home'} replace />} />
        <Route path="home" element={<ProviderSearch />} />
        <Route path="search" element={<ProviderSearch />} />
        <Route path="payment" element={<ProviderPayment />} />
        <Route path="payment/complete" element={<RedirectPage to={'/home'} />} />
      </Routes>
    </>
  )
}

export default App
