import { createSvgIcon } from "@mui/material";

export const NicaIcon = createSvgIcon(
  <>
    <path
      fill="#005387"
      d="M161.9,37.1c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.7-0.3
	c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.5,0.2
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2l0.4-0.7c-0.3-0.3-0.7-0.5-1.1-0.7
	c-0.4-0.2-0.9-0.2-1.4-0.2c-0.4,0-0.8,0.1-1.2,0.2c-0.3,0.1-0.6,0.3-0.9,0.6c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,0.9
	c0,0.3,0,0.7,0.2,1c0.1,0.2,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.5,0.2,0.7,0.2
	c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.2,0.5c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1,0.3
	c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0.1
	c-0.1,0-0.1,0.1-0.1,0.1l-0.5,0.8c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.2,0.8,0.2
	c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.9-0.1,1.2-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.4-0.5,0.6-0.9c0.1-0.3,0.2-0.7,0.2-1.1
	C162.1,37.7,162,37.4,161.9,37.1z"
    />
    <path
      fill="#005387"
      d="M169,32.2v5.1c0,0.3,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.3-0.6,0.4c-0.5,0.2-1.1,0.2-1.6,0
	c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.3-0.1-0.6-0.1-0.9v-5.1h-1.6v5.1c0,0.5,0.1,0.9,0.2,1.4
	c0.1,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.1,0.7c0.9,0.3,2,0.3,2.9,0c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.5-0.7,0.7-1.1
	c0.2-0.4,0.2-0.9,0.2-1.4v-5.1L169,32.2z"
    />
    <path
      fill="#005387"
      d="M178.4,33.8c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.3-0.7-0.4-1-0.6c-0.5-0.1-1-0.2-1.5-0.2h-2.8v8.5h1.6v-3h1.2
	c0.5,0,1-0.1,1.4-0.2c0.4-0.1,0.7-0.3,1-0.6c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.4,0.2-0.7,0.2-1.1C178.6,34.5,178.5,34.2,178.4,33.8z
	 M176.9,35.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.1-0.8,0.1h-1.2v-3h1.2c0.3,0,0.5,0,0.8,0.1
	c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6C177,35.1,177,35.4,176.9,35.6z"
    />
    <path
      fill="#005387"
      d="M186.1,33.8c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.3-0.6-0.4-1-0.6c-0.5-0.1-1-0.2-1.5-0.2h-2.8v8.5h1.6v-3h1.2
	c0.5,0,1-0.1,1.4-0.2c0.4-0.1,0.7-0.3,1-0.6c0.3-0.2,0.5-0.5,0.6-0.9C186.3,35.3,186.3,34.5,186.1,33.8z M184.6,35.6
	c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.3,0.3-0.5,0.3c-0.2,0.1-0.5,0.1-0.8,0.1h-1.2v-3h1.2c0.3,0,0.5,0,0.8,0.1
	c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6C184.7,35.1,184.7,35.4,184.6,35.6L184.6,35.6z"
    />
    <path
      fill="#005387"
      d="M195.7,34.7c-0.2-0.5-0.5-1-0.9-1.4c-0.4-0.4-0.8-0.7-1.4-0.9c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6,0-1.2,0.1-1.8,0.3
	c-1,0.4-1.8,1.2-2.2,2.3c-0.2,0.6-0.3,1.1-0.3,1.7c0,0.6,0.1,1.2,0.3,1.7c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,0.9
	c0.6,0.2,1.2,0.3,1.8,0.3c0.6,0,1.2-0.1,1.8-0.3c0.5-0.2,1-0.5,1.4-0.9c0.4-0.4,0.7-0.9,0.9-1.4c0.2-0.5,0.3-1.1,0.3-1.7
	C196,35.9,195.9,35.3,195.7,34.7z M194.2,37.7c-0.1,0.3-0.3,0.7-0.5,0.9c-0.2,0.3-0.5,0.5-0.8,0.6c-0.7,0.3-1.5,0.3-2.2,0
	c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0.1-0.8,0.2-1.2c0.1-0.3,0.3-0.7,0.5-0.9
	c0.2-0.3,0.5-0.5,0.9-0.6c0.7-0.3,1.5-0.3,2.2,0c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.3,0.4,0.6,0.5,0.9c0.1,0.4,0.2,0.8,0.2,1.2
	C194.4,36.9,194.3,37.3,194.2,37.7L194.2,37.7z"
    />
    <path
      fill="#005387"
      d="M202.1,37.6c-0.1-0.2-0.3-0.4-0.5-0.5c0.3-0.1,0.5-0.2,0.8-0.4c0.2-0.1,0.4-0.3,0.6-0.5
	c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.1-0.5,0.1-0.8c0-0.3-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.6-0.4-1-0.5
	c-0.5-0.1-1-0.2-1.5-0.2h-2.6v8.5h1.6v-3.3h0.7c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.2,0.2l1.8,2.7c0.1,0.1,0.1,0.2,0.2,0.2
	c0.1,0.1,0.2,0.1,0.4,0.1h1.4L202.1,37.6z M201,36.1c-0.3,0.1-0.5,0.1-0.8,0.1h-1v-2.8h1c0.5,0,0.9,0.1,1.3,0.3
	c0.3,0.2,0.4,0.6,0.4,1c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5C201.3,36,201.2,36.1,201,36.1z"
    />
    <path fill="#005387" d="M204.6,32.2v1.3h2.6v7.2h1.6v-7.2h2.5v-1.3H204.6z" />
    <path fill="#005387" d="M212.8,32.2v8.5h1.6v-8.5H212.8z" />
    <path
      fill="#005387"
      d="M222.8,32.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.2l-2,5.2c-0.1,0.4-0.3,0.8-0.3,1.2
	c0-0.2-0.1-0.4-0.2-0.6s-0.1-0.4-0.2-0.6l-2-5.2c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-1.3l3.4,8.5h1.4l3.4-8.5H222.8z"
    />
    <path
      fill="#005387"
      d="M230.6,33.5v-1.2h-5.3v8.5h5.3v-1.3h-3.8V37h3v-1.2h-3v-2.3H230.6z"
    />
    <path
      fill="#005387"
      d="M239.9,37.1c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.7-0.3s-0.5-0.2-0.7-0.2
	c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3
	c0.1-0.1,0.3-0.2,0.4-0.2c0.4-0.1,0.8-0.1,1.2,0c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1
	c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2l0.4-0.7c-0.3-0.3-0.7-0.5-1.1-0.7c-0.4-0.2-0.9-0.2-1.4-0.2c-0.4,0-0.8,0.1-1.2,0.2
	c-0.3,0.1-0.6,0.3-0.9,0.6c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0,0.7,0.2,1c0.1,0.2,0.2,0.5,0.4,0.7
	c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.4
	c0.1,0.2,0.2,0.3,0.2,0.5c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1,0.3c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.3
	c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1l-0.5,0.8c0.2,0.2,0.4,0.3,0.5,0.4
	c0.2,0.1,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.9-0.1,1.2-0.2c0.3-0.1,0.7-0.3,0.9-0.6
	c0.2-0.2,0.4-0.5,0.6-0.9c0.1-0.3,0.2-0.7,0.2-1.1C240.1,37.7,240,37.4,239.9,37.1z"
    />
    <path
      fill="#005387"
      d="M247,33.5v-1.2h-5.3v8.5h5.3v-1.3h-3.8V37h3v-1.2h-3v-2.3H247z"
    />
    <path
      fill="#005387"
      d="M253.2,37.6c-0.1-0.2-0.3-0.4-0.5-0.5c0.3-0.1,0.6-0.2,0.8-0.4c0.2-0.1,0.4-0.3,0.6-0.5
	c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.1-0.5,0.1-0.8c0-0.3-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.7-0.4-1-0.5
	c-0.5-0.1-1-0.2-1.5-0.2h-2.6v8.5h1.6v-3.3h0.7c0.1,0,0.2,0,0.4,0.1c0.1,0.1,0.2,0.1,0.2,0.2l1.8,2.7c0.1,0.1,0.1,0.2,0.2,0.2
	c0.1,0.1,0.2,0.1,0.4,0.1h1.4L253.2,37.6z M252.1,36.1c-0.2,0.1-0.5,0.1-0.8,0.1h-1v-2.8h1c0.5,0,0.9,0.1,1.3,0.3
	c0.3,0.2,0.4,0.6,0.4,1c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5C252.5,36,252.3,36.1,252.1,36.1z"
    />
    <path
      fill="#005387"
      d="M262.9,32.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.2l-2,5.2c-0.2,0.4-0.3,0.8-0.3,1.2
	c0-0.2-0.1-0.4-0.2-0.6s-0.1-0.4-0.2-0.6l-2-5.2c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-1.3l3.4,8.5h1.4l3.4-8.5H262.9z"
    />
    <path fill="#005387" d="M265.6,32.2v8.5h1.6v-8.5H265.6z" />
    <path
      fill="#005387"
      d="M275.2,38.7c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.2
	c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6,0c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.3-0.4-0.6-0.5-0.9
	c-0.1-0.4-0.2-0.8-0.2-1.3c0-0.4,0.1-0.8,0.2-1.2c0.1-0.3,0.3-0.7,0.6-0.9c0.2-0.3,0.5-0.5,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2
	c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2-0.1
	c0,0,0.1-0.1,0.1-0.1l0.5-0.7c-0.4-0.3-0.8-0.6-1.2-0.8c-0.5-0.2-1.1-0.3-1.6-0.3c-0.6,0-1.2,0.1-1.8,0.3c-1,0.4-1.9,1.2-2.3,2.3
	c-0.2,0.6-0.3,1.2-0.3,1.8c0,0.6,0.1,1.2,0.3,1.7c0.2,0.5,0.5,1,0.8,1.4c0.4,0.4,0.8,0.7,1.3,0.9c0.5,0.2,1.1,0.3,1.7,0.3
	c0.6,0,1.2-0.1,1.8-0.3c0.5-0.2,0.9-0.6,1.3-1l-0.6-0.7C275.4,38.7,275.3,38.7,275.2,38.7z"
    />
    <path
      fill="#005387"
      d="M283,33.5v-1.2h-5.3v8.5h5.3v-1.3h-3.8V37h3v-1.2h-3v-2.3H283z"
    />
    <path
      fill="#005387"
      d="M289.6,37.1c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.7-0.3
	c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.5,0.2
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2l0.4-0.7c-0.3-0.3-0.7-0.5-1.1-0.7
	c-0.4-0.2-0.9-0.2-1.4-0.2c-0.4,0-0.8,0.1-1.2,0.2c-0.3,0.1-0.6,0.3-0.9,0.6c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,0.9
	c0,0.3,0,0.7,0.2,1c0.1,0.2,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.5,0.2,0.7,0.2
	c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.3,0.2,0.5c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1,0.3
	c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1l-0.5,0.8c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.2,0.8,0.2
	c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.9-0.1,1.2-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.4-0.5,0.6-0.9c0.1-0.3,0.2-0.7,0.2-1.1
	C289.8,37.7,289.7,37.4,289.6,37.1z"
    />
    <path
      fill="#005387"
      d="M299.3,33.5v-1.2h-5.3v8.5h1.6v-3.4h3.2V36h-3.2v-2.5H299.3z"
    />
    <path
      fill="#005387"
      d="M308.7,34.7c-0.2-0.5-0.5-1-0.9-1.4c-0.4-0.4-0.8-0.7-1.4-0.9c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6,0-1.2,0.1-1.8,0.3
	c-1,0.4-1.8,1.2-2.2,2.3c-0.2,0.6-0.3,1.1-0.3,1.7c0,0.6,0.1,1.2,0.3,1.7c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.3,0.9
	c0.6,0.2,1.2,0.3,1.8,0.3c0.6,0,1.2-0.1,1.8-0.3c0.5-0.2,1-0.5,1.4-0.9c0.4-0.4,0.7-0.9,0.9-1.4c0.2-0.5,0.3-1.1,0.3-1.7
	C309,35.9,308.9,35.3,308.7,34.7z M307.2,37.7c-0.1,0.3-0.3,0.7-0.5,0.9c-0.2,0.3-0.5,0.5-0.8,0.6c-0.7,0.3-1.5,0.3-2.2,0
	c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0.1-0.8,0.2-1.2c0.1-0.3,0.3-0.7,0.5-0.9
	c0.2-0.3,0.5-0.5,0.8-0.6c0.7-0.3,1.5-0.3,2.2,0c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.3,0.4,0.6,0.5,0.9c0.1,0.4,0.2,0.8,0.2,1.2
	C307.4,36.9,307.3,37.3,307.2,37.7L307.2,37.7z"
    />
    <path
      fill="#005387"
      d="M315.1,37.6c-0.1-0.2-0.3-0.4-0.5-0.5c0.3-0.1,0.5-0.2,0.8-0.4c0.2-0.1,0.4-0.3,0.6-0.5
	c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.1-0.5,0.1-0.8c0-0.3-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.6-0.4-1-0.5
	c-0.5-0.1-1-0.2-1.5-0.2h-2.6v8.5h1.6v-3.3h0.7c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2l1.8,2.7c0.1,0.1,0.1,0.2,0.2,0.2
	c0.1,0.1,0.2,0.1,0.3,0.1h1.4L315.1,37.6z M314,36.1c-0.2,0.1-0.5,0.1-0.8,0.1h-1v-2.8h1c0.5,0,0.9,0.1,1.3,0.3
	c0.3,0.2,0.4,0.6,0.4,1c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5C314.4,36,314.2,36.1,314,36.1L314,36.1z"
    />
    <path
      fill="#005387"
      d="M172.9,48.5v-1.2h-5.4v8.5h1.6v-3.4h3.2V51h-3.2v-2.5H172.9z"
    />
    <path
      fill="#005387"
      d="M177.8,47.3h-1.6l-3.3,8.5h1.2c0.2,0,0.4-0.1,0.5-0.3l0.6-1.7h3.5l0.6,1.7c0,0.1,0.1,0.2,0.2,0.2
	c0.1,0.1,0.2,0.1,0.3,0.1h1.2L177.8,47.3z M175.6,52.5l1-2.8c0-0.1,0.1-0.3,0.2-0.4s0.1-0.4,0.2-0.6c0,0.2,0.1,0.4,0.2,0.6
	s0.1,0.3,0.2,0.4l1,2.8H175.6z"
    />
    <path
      fill="#005387"
      d="M190.3,47.3c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-2.5,4.7
	c-0.1,0.1-0.1,0.2-0.2,0.4s-0.1,0.3-0.2,0.4c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.3-0.2-0.4l-2.5-4.7c0-0.1-0.1-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0h-1.2v8.5h1.4v-5.5c0-0.1,0-0.2,0-0.3s0-0.2,0-0.4l2.6,4.8
	c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.2c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3l2.6-4.8c0,0.1,0,0.2,0,0.4
	s0,0.2,0,0.3v5.5h1.4v-8.5H190.3z"
    />
    <path fill="#005387" d="M193.7,47.3v8.5h1.6v-8.5H193.7z" />
    <path fill="#005387" d="M199.2,54.5v-7.2h-1.6v8.5h5v-1.3H199.2z" />
    <path fill="#005387" d="M204.2,47.3v8.5h1.6v-8.5H204.2z" />
    <path
      fill="#005387"
      d="M213.4,48.5v-1.2H208v8.5h5.4v-1.3h-3.8v-2.4h3v-1.2h-3v-2.3H213.4z"
    />
    <path
      fill="#005387"
      d="M220,52.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.7-0.3
	c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.5,0.2
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2l0.4-0.7c-0.3-0.3-0.7-0.5-1.1-0.7
	c-0.4-0.2-0.9-0.2-1.4-0.2c-0.4,0-0.8,0.1-1.2,0.2c-0.3,0.1-0.6,0.3-0.9,0.6c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,0.9
	c0,0.3,0,0.7,0.2,1c0.1,0.2,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.5,0.2,0.7,0.2
	c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.3,0.2,0.5c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1,0.3
	c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0.1
	c-0.1,0-0.1,0.1-0.1,0.1l-0.5,0.8c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.1,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.2,0.8,0.2
	c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.9-0.1,1.2-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.3-0.2,0.4-0.5,0.6-0.9c0.1-0.3,0.2-0.7,0.2-1.1
	C220.2,52.8,220.1,52.5,220,52.2z"
    />
    <path
      fill="#005387"
      d="M230.1,53.9c0.3-0.4,0.5-0.8,0.6-1.3c0.1-0.5,0.2-1,0.2-1.5H230c-0.2,0-0.3,0.1-0.3,0.3c0,0,0,0,0,0
	c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8l-2.2-2.3c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.2-0.2-0.3
	c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2
	c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3
	c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1h0.1l0.9-0.2c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.6
	c-0.2-0.2-0.4-0.3-0.7-0.4c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.7,0-1,0.2c-0.3,0.1-0.5,0.3-0.8,0.5c-0.2,0.2-0.4,0.4-0.5,0.7
	c-0.1,0.3-0.2,0.6-0.2,0.8c0,0.3,0,0.6,0.1,0.8c0.1,0.3,0.3,0.6,0.5,0.9c-0.2,0.1-0.5,0.3-0.7,0.4c-0.2,0.2-0.4,0.3-0.5,0.6
	c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.3,0.1,0.7,0.2,1c0.1,0.3,0.3,0.6,0.6,0.8c0.2,0.2,0.5,0.4,0.8,0.5
	c0.3,0.1,0.6,0.2,1,0.2c0.5,0,1-0.1,1.5-0.3c0.5-0.2,0.9-0.4,1.2-0.7l0.6,0.6c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
	c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h1.4L230.1,53.9z M227.7,54.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.2,0-0.4,0-0.5-0.1
	c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0.1-0.6,0.2-0.8c0.2-0.3,0.4-0.5,0.7-0.7
	l2.2,2.3C228.2,54.3,228,54.4,227.7,54.5L227.7,54.5z"
    />
    <path
      fill="#005387"
      d="M241.6,48.9c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.3-0.6-0.4-1-0.6c-0.5-0.1-1-0.2-1.5-0.2h-2.8v8.5h1.6v-3h1.2
	c0.5,0,1-0.1,1.4-0.2c0.4-0.1,0.7-0.3,1-0.6c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.4,0.2-0.7,0.2-1.1C241.8,49.6,241.7,49.2,241.6,48.9z
	 M240.1,50.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0.1-0.5,0.1-0.8,0.1h-1.2v-3h1.2c0.3,0,0.5,0,0.8,0.1
	c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6C240.2,50.2,240.2,50.4,240.1,50.6L240.1,50.6z"
    />
    <path
      fill="#005387"
      d="M248.9,47.3v3.6h-4v-3.6h-1.6v8.5h1.6v-3.7h4v3.7h1.6v-8.5H248.9z"
    />
    <path
      fill="#005387"
      d="M258,47.3c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2l-1.5,2.8c-0.1,0.2-0.2,0.3-0.2,0.5s-0.1,0.3-0.2,0.4
	c0-0.1-0.1-0.3-0.2-0.4s-0.1-0.3-0.2-0.5l-1.5-2.8c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1h-1.4l3.1,5.2v3.3h1.6v-3.3
	l3.1-5.2L258,47.3z"
    />
    <path
      fill="#005387"
      d="M265.5,52.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.7-0.3
	c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.5,0.2
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2l0.4-0.7c-0.3-0.3-0.7-0.5-1.1-0.7
	c-0.4-0.2-0.9-0.2-1.4-0.2c-0.4,0-0.8,0.1-1.2,0.2c-0.3,0.1-0.6,0.3-0.9,0.6c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,0.9
	c0,0.3,0,0.7,0.2,1c0.1,0.2,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.5,0.2,0.7,0.2
	c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.2,0.5c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1,0.3
	c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0.1
	c-0.1,0-0.1,0.1-0.1,0.1l-0.5,0.8c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.1,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.2,0.8,0.2
	c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.9-0.1,1.2-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.3,0.2-0.7,0.2-1.1
	C265.7,52.8,265.6,52.5,265.5,52.2z"
    />
    <path fill="#005387" d="M267.4,47.3v8.5h1.6v-8.5H267.4z" />
    <path
      fill="#005387"
      d="M277.1,53.8c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.2
	c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6,0c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.3-0.4-0.6-0.5-0.9
	c-0.1-0.4-0.2-0.8-0.2-1.3c0-0.4,0.1-0.8,0.2-1.2c0.1-0.3,0.3-0.7,0.6-0.9c0.2-0.3,0.5-0.5,0.9-0.6c0.6-0.2,1.3-0.3,1.9-0.1
	c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1l0.5-0.7
	c-0.4-0.3-0.8-0.6-1.2-0.8c-0.5-0.2-1.1-0.3-1.7-0.3c-0.6,0-1.2,0.1-1.8,0.3c-1,0.4-1.9,1.2-2.3,2.3c-0.2,0.6-0.3,1.2-0.3,1.8
	c0,0.6,0.1,1.2,0.3,1.7c0.2,0.5,0.5,1,0.8,1.4c0.4,0.4,0.8,0.7,1.3,0.9c0.5,0.2,1.1,0.3,1.7,0.3c0.6,0,1.2-0.1,1.8-0.3
	c0.5-0.2,0.9-0.6,1.3-1l-0.6-0.7C277.2,53.8,277.2,53.8,277.1,53.8z"
    />
    <path fill="#005387" d="M279.7,47.3v8.5h1.6v-8.5H279.7z" />
    <path
      fill="#005387"
      d="M287.6,47.3H286l-3.3,8.5h1.2c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.6-1.7h3.5l0.6,1.7
	c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1h1.2L287.6,47.3z M285.5,52.5l1-2.8c0-0.1,0.1-0.3,0.2-0.4s0.1-0.4,0.2-0.6
	c0,0.2,0.1,0.4,0.2,0.6s0.1,0.3,0.2,0.4l1,2.8H285.5z"
    />
    <path
      fill="#005387"
      d="M297.9,47.3v5.2c0,0.1,0,0.2,0,0.4s0,0.3,0,0.4l-4.5-5.7c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0h-0.8v8.5h1.4v-5.2c0-0.1,0-0.2,0-0.3s0-0.2,0-0.4l4.4,5.6c0.1,0.1,0.1,0.2,0.2,0.2
	c0.1,0,0.2,0.1,0.3,0.1h0.8v-8.5L297.9,47.3z"
    />
    <path
      fill="#005387"
      d="M306.3,52.2c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.7-0.3
	c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.3,0.1-0.4
	c0-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.5,0.2
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2l0.4-0.7c-0.3-0.3-0.7-0.5-1.1-0.7
	c-0.4-0.2-0.9-0.2-1.4-0.2c-0.4,0-0.8,0.1-1.2,0.2c-0.3,0.1-0.6,0.3-0.9,0.6c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,0.9
	c0,0.3,0,0.7,0.2,1c0.1,0.2,0.3,0.5,0.5,0.7c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.5,0.2,0.7,0.2
	c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.3,0.2,0.5c0,0.3-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1,0.3
	c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1l-0.5,0.8c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.1,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.2,0.8,0.2
	c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.9-0.1,1.2-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.3,0.2-0.7,0.2-1.1
	C306.5,52.8,306.4,52.5,306.3,52.2z"
    />
    <rect fill="#009dac" x="43.8" y="25" width="5.1" height="38" />
    <path
      fill="#009dac"
      d="M94.7,56.8l-0.3,0.3c-0.7,0.7-1.3,1.3-2,1.8c-0.6,0.6-1.3,1.1-2,1.5c-1.2,0.9-2.6,1.6-4,2.1
	c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0.1-0.5,0.2-0.7,0.2c-2.1,0.7-4.3,1-6.5,0.9h-0.1c-0.3,0-0.6,0-0.8,0c-1.6-0.1-3.1-0.3-4.6-0.8
	c-0.4-0.1-0.8-0.2-1.1-0.4c-0.4-0.1-0.7-0.3-1.1-0.4c-0.5-0.2-1-0.4-1.4-0.7c-5.5-3-9.2-8.5-10-14.8c0-0.3-0.1-0.5-0.1-0.8
	c-0.1-0.7-0.1-1.4-0.1-2.1v-0.1c0-1.4,0.1-2.7,0.4-4.1c0.1-0.3,0.1-0.7,0.2-1c2.1-8.8,9.9-15,19-15c0.1,0,0.3,0,0.4,0
	c0.6,0,1.2,0,1.8,0.1c0.1,0,0.2,0,0.2,0c4.8,0.4,9.3,2.6,12.6,6l0.4,0.3l-0.2,0.2L91,34l-0.2,0.2l-0.4-0.3
	c-3.7-3.5-7.2-5.1-11.5-5.1c-8.1,0-14.1,6.4-14.1,15v0.1c-0.1,4.3,1.7,8.5,4.8,11.5c0.6,0.5,1.2,1,1.9,1.4c1.7,1.1,3.6,1.8,5.6,2.1
	c0.3,0,0.7,0.1,1,0.1c0.3,0,0.5,0,0.8,0c4.2,0,7.4-1.3,10.9-4.4c0.3-0.3,0.6-0.6,0.9-0.8l0.3-0.3l0.1,0.1l0.2,0.2L94.7,56.8z"
    />
    <path
      fill="#c66b7f"
      d="M74.4,50.7c-0.7-0.3-1.5-0.2-2.1,0.3c0.6-0.1,1.2,0,1.8,0.3c0.5,0.3,0.9,0.8,1.2,1.2c0.4,0.5,0.7,1,1,1.6
	s0.6,1.2,1,1.7c0-1.4-0.5-2.7-1.2-3.9C75.7,51.5,75.1,51,74.4,50.7z"
    />
    <path
      fill="#c66b7f"
      d="M82.8,53.7c0.2-0.5,0.4-1,0.6-1.5c0.2-0.5,0.5-0.9,0.9-1.3c0.4-0.4,0.8-0.7,1.3-0.9c-0.6,0-1.2,0.2-1.7,0.5
	c-0.5,0.4-0.9,0.8-1.2,1.4c-0.3,0.5-0.4,1.1-0.5,1.7c0,0.3,0,0.6,0,0.9c0,0.3,0.1,0.6,0.1,0.9L82.8,53.7z"
    />
    <path
      fill="#c66b7f"
      d="M89.2,49.6c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.3-0.3,0.4-0.5
	c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.3,0.3-0.7,0.4-1.1c0-0.2,0-0.4,0.1-0.5c0-0.2,0-0.4,0-0.6c-0.1-0.6-0.2-1.1-0.5-1.6
	c-0.2-0.3-0.4-0.6-0.7-0.9c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.1
	l-0.1,0c-2.1-0.4-4.1,1-4.5,3.1c-0.1,0.4-0.1,0.8,0,1.1c-0.9,0.5-1.2,1.7-0.7,2.6c0.3,0.6,0.9,0.9,1.6,0.9c-2-1.6-0.4-2.9,0-3.2
	c-0.1-1.6,1-3,2.6-3.1c0.3,0,0.6,0,0.9,0.1c3.4,0.8,2.7,4.9,0.2,6.4c0.2,0.1,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.3
	c0.2,0.1,0.3,0.3,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.1-0.4
	c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.1-0.2-0.2C89.1,49.8,89.1,49.7,89.2,49.6
	C89.1,49.6,89.1,49.6,89.2,49.6z"
    />
    <path
      fill="#c66b7f"
      d="M78.9,46.7c0.4,0.1,0.9,0.1,1.3-0.1c0.4-0.1,0.7-0.3,1.1-0.5c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.3,0.5-0.5,0.8-0.8
	c0.9-1.2,1.6-2.5,2.1-3.8c0.2-0.7,0.5-1.3,0.6-2c0.2-0.7,0.3-1.4,0.3-2.1c0-0.6-0.1-1.3-0.3-1.8c-0.2-0.4-0.4-0.8-0.7-1.1
	c-0.4-0.6-1.1-1-1.8-1.1c0,0-0.4,0.1-0.4,0c-3.3-3.7-15.3,0.6-11.7,13.3c-1-10.2,6.2-15,10.7-12.7c-1.9,0.4-3.5,1.6-4.6,3.3
	c1.4-1.5,3.3-2.5,5.4-2.8c1,0,1.8,1,2.2,1.7c0.2,0.3,0.3,0.6,0.3,1c0.1,0.4,0.1,0.7,0,1.1c-0.1,0.3-0.3,0.5-0.6,0.7
	c-0.5,0.4-1.1,0.8-1.7,1c-1.1,0.4-2.2,0.8-3.3,1.1L79,40.4c-1.1,0.3-2.1,0.7-3.2,1.1c-1.1,0.4-2.1,1.1-2.8,2c-0.7,1-1.3,2.1-1.8,3.2
	c-0.5,1.2-0.9,2.4-1.2,3.6c-0.3,1.2,0.1,2.5,1,3.3c-0.1,0,0-0.6,0-0.7c0-0.2,0-0.5,0.1-0.7c0-0.5,0.1-0.9,0.2-1.3c0.2-1,0.4-2,0.7-3
	c0.2-0.9,0.5-1.9,0.9-2.8c0.3-0.6,0.6-1.2,1.1-1.6c0.5-0.4,1.1-0.8,1.7-1c0.9-0.4,1.9-0.7,2.8-0.9c1.3-0.3,2.6-0.7,3.8-1.2
	c1-0.4,1.9-1.1,2.7-1.9c0,0,0,0,0,0c-0.2,0.7-0.4,1.4-0.8,2.1c-0.6,1.3-1.3,2.4-2.2,3.5c-0.4,0.5-0.9,1-1.4,1.4
	c-0.5,0.4-1.1,0.6-1.7,0.5c-0.6-0.2-1.1-0.6-1.4-1.1c-0.3-0.6-0.6-1.3-0.8-1.9c0,0.5,0,1,0.2,1.5c-0.5,1.5-0.7,3.2-0.3,4.8
	c0.4,1.1,1.2,2.1,2.1,2.9c0.4,0.4,0.8,0.9,1.1,1.3c0.3,0.5,0.6,1,0.9,1.6c0.1-0.7,0-1.3-0.2-1.9c-0.3-0.6-0.6-1.2-1.1-1.6
	c-0.4-0.4-0.9-0.8-1.3-1.2c-0.4-0.3-0.9-0.7-0.9-1.2c-0.1-1,0.2-2,0.7-2.9C78.2,46.4,78.5,46.6,78.9,46.7z"
    />
    <path
      fill="#009dac"
      d="M124,24.7h-4.6l-5.1,11.2c-2.6,5.8-5.2,11.7-7.9,17.5c-2.1,4.5-3.9,9.1-6.7,13.2c-2.7,3.9-6.1,7.3-10.1,9.9
	c-7.9,5.2-17.5,7.5-26.9,6.3C55.8,82,49.3,79.2,43.8,75v5.9c0,0.4,2.6,1.6,3,1.8c1.1,0.6,2.2,1.1,3.3,1.6c6.9,3,14.4,4.2,21.9,3.5
	c13.9-1.3,26.4-9.2,33.6-21.3c2-3.6,3.7-7.3,5.3-11l1-2.2h19.5l4.3,9.8h5.6L124,24.7z M114,48.4l7.7-17.2l7.6,17.2H114z"
    />
    <path
      fill="#009dac"
      d="M67.8,4.8c13.5,0,26.1,7,33.3,18.5l3.4-3.6C91-0.6,63.7-6.1,43.5,7.3C31.2,15.5,23.8,29.2,23.8,44
	c0,1.6,0.1,3.3,0.3,4.9L5.3,25H0v38h5.1V33.6l23,29.4h0c0,0,0,0,0,0h5.4c0,0,0-0.1,0-0.1C23,44,29.9,20.1,48.8,9.6
	C54.6,6.4,61.1,4.8,67.8,4.8L67.8,4.8z"
    />
  </>,
  "NicaIcon"
);
