import { loadConfig } from '@kikoda/generated-config-hooks'
import axios, { AxiosError, AxiosResponse, AxiosStatic } from 'axios'
import { AppConfig } from 'hooks/useAppConfig'

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`)
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse) => {
  return response
}

const configureAxios = (axios: AxiosStatic) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(async (val) => {
    val.baseURL =
      (await loadConfig<AppConfig>('')).additionalConfig?.backendUrl ??
      'https://dev.api.cares.nica.com'
    val.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return val
  }, onRequestError)
  axiosInstance.interceptors.response.use(onResponse)
  return axiosInstance
}

export const axiosInstance = configureAxios(axios)
