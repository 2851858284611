import { useQuery } from 'react-query'
import { queryKeys } from './constants'
import { getPhysicianBalances } from '@kikodacode/cares-api'
import { axiosInstance } from './axiosConfig'

export interface useProviderSearchProps {
  searchTerm?: string
}

export const useProviderSearch = ({ searchTerm }: useProviderSearchProps) => {
  const criteria = preProcessSearchString(searchTerm)

  const { data, isLoading } = useQuery(
    queryKeys.physicians(criteria),
    async () => {
      return await getPhysicianBalances(
        {
          physicianName: criteria.physicianName,
          licenseNumber: criteria.physicianLicense,
        },
        axiosInstance
      )
    },
    {
      enabled:
        criteria.physicianLicense.length > 0 ||
        criteria.physicianName.length > 0,
    }
  )
  // process data returned from lambda call
  const results = data?.data.map((item) => {
    const name = item.fullName.split(',')
    return {
      id: item.physicianId.toString(),
      firstName: name[1] ?? '',
      lastName: name[0] ?? '',
      balance: item.amountDue,
      license: item.licenseNumber,
      statusId: item.statusId,
      legalCanPay: item.legalCanPay,
      pendingPaymentStatus: item.pendingPaymentStatus,
    }
  })
  return { results: results ?? [], loading: isLoading }
}

const preProcessSearchString = (searchTerm?: string) => {
  const terms = searchTerm?.split(' ')
  const criteria = {
    physicianLicense: '',
    physicianName: '',
  }
  if (searchTerm && searchTerm?.length > 0) {
    terms?.forEach((x) => {
      if (x.match(/\d+/g)) {
        criteria.physicianLicense = x
      } else if (x.match(/[a-zA-Z]+/g)) {
        if (criteria.physicianName === '') {
          criteria.physicianName = x
        } else {
          criteria.physicianName = `${x}, ${criteria.physicianName}`
        }
      }
    })
  }
  return criteria
}
