export const theme = {
  palette: {
    primary: {
      main: "#009dac",

    },
    secondary: {
      main: '#ff7000'
    },
    info: {
      main: '#005387'
    }

  },
  typography: {

    fontFamily: [
      'Montserrat', 'sans-serif'
    ].join(','),
  }
};
