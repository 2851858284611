import React from 'react'
import { NumericFormatProps, NumericFormat } from 'react-number-format'
import { Input, InputProps } from './Input'

export interface NumberInputProps
  extends Omit<NumericFormatProps<InputProps>, 'customInput'> {}

export const NumberInput: React.FC<NumberInputProps> = React.forwardRef<
  typeof NumericFormat<number | string>,
  NumberInputProps
>((props, ref) => {
  return <NumericFormat {...props} getInputRef={ref} customInput={Input} />
})
