import { Divider, Grid, SxProps, Theme, Typography } from "@mui/material";
import { Button } from "components/Elements/Buttons/Button";
import { IconButton } from "components/Elements/Buttons/IconButton";

import { NicaIconWhite } from "components/Elements/Buttons/NicaIconWhite";

export interface FooterProps {
  sx?: SxProps<Theme>;
}

export const Footer: React.FC<FooterProps> = ({ sx }) => {
  return (
    <Grid
      container
      sx={{
        ...sx,
        height: 140,
        justifyContent: "center",
        backgroundColor: "#005387",
      }}
    >
      <Grid item alignSelf="center">
        <IconButton
          disableRipple
          sx={{ marginRight: 5 }}
          href="https://nica.com/"
        >
          <NicaIconWhite
            viewBox="0 0 317.3 87.9"
            sx={{ height: 90, width: 320 }}
          />
        </IconButton>
      </Grid>
      <Grid item container xs={6} alignSelf="center">
        <Grid item marginRight={"auto"}>
          <Typography color={"white"} variant="body1">
            The Florida Birth-Related Neurological Injury Compensation
            Association
          </Typography>
        </Grid>
        <Grid item color={"white"}>
          <Button
            disableRipple
            color="inherit"
            variant="text"
            size="small"
            href="https://nica.com/news"
          >
            News
          </Button>
        </Grid>
        <Grid item color={"white"}>
          <Button
            disableRipple
            color="inherit"
            variant="text"
            size="small"
            href="https://nica.com/contact/"
          >
            Contact
          </Button>
        </Grid>

        <Divider
          sx={{
            width: "100%",
            borderColor: "#FFFFFF",
            borderWidth: 1,
            marginY: 1,
          }}
        />

        <Grid item marginRight={"auto"}>
          <Typography color={"white"} variant="body1">
            Provider Payment Portal
          </Typography>
        </Grid>
        <Grid item color={"white"}>
          <Button
            disableRipple
            color="inherit"
            variant="text"
            size="small"
            href="https://nica.com/privacy-notice/"
          >
            Privacy Notice
          </Button>
        </Grid>
        <Grid item color={"white"}>
          <Button
            disableRipple
            color="inherit"
            variant="text"
            size="small"
            href="https://nica.com/disclaimer/"
          >
            Disclaimer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
