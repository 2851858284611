import { Theme } from '@emotion/react';
import { Box, SxProps } from '@mui/material';

export interface TextBoxProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}
export const TextBox: React.FC<TextBoxProps> = ({ sx, children }) => {
  return <Box sx={{ backgroundColor: '#ffb880', padding: 3, ...sx, borderRadius: '5px' }}>{children}</Box>;
};
