import React, { useEffect, useState } from 'react'
import { Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Button } from 'components/Elements/Buttons/Button'
import { PhysicianSelect } from '../components/Elements/Inputs/PhysicianSelect'
import { useProviderSearch } from 'api/useProviderSearch'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { useProviderLookup } from 'api/useProviderLookup'
import useDebounce from 'utils/useDebounce'
import { PageWrapper } from '../components/Modules/Wrappers/PageWrapper'
import {
  PhysicianResult,
  PaymentItem,
  PhysicianSearchGrid,
} from 'components/Modules/DataDisplay/PhysicianSearchGrid'
import { TextBox } from 'components/Elements/Typography/TextBox'
import { ConfirmPaymentDialog } from 'components/Elements/Dialogs/ConfirmPaymentDialog'

export interface ProviderSearhProps {}

export interface PaymentData {
  paymentItems: Array<PaymentItem>
  total: string
}

const calculateTotal = (values: Array<PaymentItem>) => {
  return values
    .reduce((partialSum, a) => {
      if (typeof a.amount === 'string' && a.amount === '') {
        return partialSum
      } else if (typeof a.amount === 'string') {
        return partialSum + parseFloat(a.amount.replace(/[^0-9.-]+/g, ''))
      } else {
        return partialSum + a.amount
      }
    }, 0)
    .toFixed(2)
}

export const ProviderSearch: React.FC<ProviderSearhProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const breakpointMatch = useMediaQuery(theme.breakpoints.down('lg'))

  const [searchParams] = useSearchParams()
  const { providers: provider } = useProviderLookup({
    id: +(searchParams.get('physicianId') ?? '0'),
  })

  const [rows, setRows] = React.useState<
    Array<PaymentItem & { isNew?: boolean }>
  >([])
  const [ids, setIds] = useState<Array<string>>([])
  const [open, setOpen] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedValue = useDebounce<string>(searchTerm, 500)
  const { results, loading } = useProviderSearch({ searchTerm: debouncedValue })

  const onPayNow = () => {
    setShowConfirmation(true)
  }

  const handleClick = (item: PhysicianResult) => {
    if (rows.length < 30)
    {
      setRows((oldRows) => [
        ...oldRows,
        {
          ...item,
          balance:
            item.statusId === 6 && item.legalCanPay ? 'legal' : item.balance,
          amount: item.statusId === 6 && item.legalCanPay ? '0' : item.balance,
        },
      ])
    }
    else {
      alert("You can only select up to 30 providers at a time.")
    }
  }

  const handleDeleteClick = (id: string) => {
    setRows(rows.filter((row) => row.id !== id))
  }

  useEffect(() => {
    setIds(rows.map((x) => x.id))
  }, [rows])

  useEffect(() => {
    if (provider && !rows.find((x) => x.id === provider.id)) {
      setRows([...rows, provider])
    }
  }, [provider, rows])

  return (
    <PageWrapper>
      <ConfirmPaymentDialog
        open={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        handleConfirm={(token) => {
          const data = rows.map((item) => ({
            ...item,
            amount: parseFloat(item.amount.toString()).toFixed(2),
          }))
          navigate('/payment', { state: { details: data, token } })
        }}
        items={rows.map((item) => ({
          ...item,
          amount: parseFloat(item.amount.toString()).toFixed(2),
        }))}
      />
      <Typography mb={1} variant="h5" fontWeight="bold" color="inherit">
        Provider Payment Portal - NICA's Pay Online Feature
      </Typography>
      <Typography mb={3} variant="body1" color="inherit">
        Pay your fee by credit card here online. It's free, quick, easy, and secure. A provided phone number will be used by NICA if there are questions regarding payment.
      </Typography>
      <Typography mb={3} variant="body1" color="inherit">
        NICA's electronic billing process is designed to enhance your annual payment experience, reduce waste, eliminate unwanted mail, ensure the security of your payment, and avoid the additional fee for using a credit card.
      </Typography>
      <TextBox sx={{ marginBottom: 3 }}>
        <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1} sx={{ textDecoration: 'underline' }}>
          PLEASE READ - IMPORTANT NOTICES:
        </Typography>
        <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1}>
          - Mobile devices are not supported at this time.
        </Typography>
        <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1}>
          - Payments made through NICA's Pay Online feature will post as “PURCHASE FLORIDA BIRT TALLAHASSEE FL” on your credit card statement.
        </Typography>
        <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1}>
          - Do not pay if you are exempt. If you are unsure if you qualify for an exemption, please visit: <Link href="https://nica.com/medical-providers/exemptions/#documents-required" target='_BLANK'>Exemptions</Link>. 
        </Typography>
        <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1}>
          - If you have been instructed to deduct <Typography display='inline' fontWeight="bold" variant="body1" color="#4f4f4f" sx={{ textDecoration: 'underline'}}>INTEREST</Typography> from your payment, the amount can only be edited on this screen.
          If you fail to deduct the proper interest amount, NICA <Typography display='inline' fontWeight="bold" variant="body1" color="#4f4f4f" sx={{ textDecoration: 'underline'}}>CANNOT</Typography> refund it.
        </Typography>
        <Typography fontWeight="bold" variant="body1" color="#4f4f4f" mb={1}>
          - If the provider HAS NOT paid in full, because payments take up to two (2) business days to post, the provider will still remain visible in the search, and duplicate payments CAN be made.
        </Typography>
        <Typography fontWeight="bold" variant="body1" color="#4f4f4f" >
          - The limit for the number of physicians that can be selected for a single payment is 30.
        </Typography>
      </TextBox>
      <Typography mb={3} variant="body1" color="inherit">
      To pay balance due for a physician, please search Physician Name or License Number and select the physician(s). Use your invoice email to verify your name, address, license, and invoice amount. Please validate the License Number is correct prior to proceeding to the payment screen.
      </Typography>
      <div style={{ height: '35vh', width: '100%' }}>
        <PhysicianSearchGrid
          handleDeleteClick={handleDeleteClick}
          rows={rows}
          setRows={setRows}
        />
        <Grid
          container
          mt={1}
          justifyContent={breakpointMatch ? 'center' : 'space-between'}
        >
          <Grid item xs={12} lg={9}>
            <PhysicianSelect
              open={open}
              setOpen={setOpen}
              handleClick={handleClick}
              selectedIds={[...ids]}
              results={results}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              loading={loading}
            />
          </Grid>
          <Grid
            item
            alignItems="center"
            display="flex"
            mt={breakpointMatch ? 2 : 0}
          >
            <Typography mr={4} fontWeight={'bold'}>{`Total: $${calculateTotal(
              rows
            )}`}</Typography>

            <Button
              disabled={rows.length === 0}
              type="button"
              size="medium"
              variant="contained"
              color="primary"
              onClick={onPayNow}
            >
              Pay Now
            </Button>
          </Grid>
        </Grid>
      </div>
    </PageWrapper>
  )
}
