import { Autocomplete, SxProps, Theme } from '@mui/material'

// TODO: Fix types here
export interface SelectProps<T> {
  open?: boolean
  onOpen?: (event: React.SyntheticEvent) => void
  onClose?: (event: React.SyntheticEvent, reason: string) => void
  onChange?: any
  onBlur?: any
  onClick?: any
  inputValue?: string
  onInputChange?: (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => void
  renderOption?: (props: object, option: T, state: object) => React.ReactNode
  size?: 'small' | 'medium'
  value?: any
  loading?: boolean
  options: Array<any>
  renderInput: (params: object) => React.ReactNode
  getOptionDisabled?: (option: T) => boolean
  disableCloseOnSelect?: boolean
  multiple?: boolean
  getOptionLabel?: (option: T) => string
  fullWidth?: boolean
  clearOnEscape?: boolean
  blurOnSelect?: 'mouse' | 'touch' | boolean
  disablePortal?: boolean
  openOnFocus?: boolean
  sx?: SxProps<Theme>
  filterOptions?: any
  loadingText?: string
  noOptionsText?: string
  disabled?: boolean
}

export const Select = <T,>(props: SelectProps<T>) => {
  return <Autocomplete {...props} />
}
