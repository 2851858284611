import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const CustomInput = styled(TextField)``;

export interface InputProps {
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  variant: 'filled' | 'outlined' | 'standard';
  size: 'medium' | 'small';
  margin?: 'dense' | 'none' | 'normal';
  fullWidth?: boolean;
  value?: any;
  defaultValue?: string;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  sx?: any; // TODO: revisit typing on this
  error?: boolean;
  helperText?: string;
  inputRef?: any;
  InputProps?: any;
  ref?: any;
}

export const Input: React.FC<InputProps> = React.forwardRef<any>((props, ref) => {
  return <CustomInput {...props} ref={ref} />;
});
