import { useQuery } from 'react-query'
import { queryKeys } from './constants'
import { getPhysician } from '@kikodacode/cares-api'
import { axiosInstance } from './axiosConfig'

export interface useProviderLookupProps {
  id: number
}

export const useProviderLookup = ({ id }: useProviderLookupProps) => {
  const { data } = useQuery(
    queryKeys.providerById(id),
    async () => {
      return await getPhysician(id, axiosInstance)
    },
    {
      enabled: !!id,
    }
  )
  // Return the provider found
  return {
    providers: data?.data
      ? {
          id: data.data.physicianId.toString(),
          firstName: data.data.fullName.split(',')[1] ?? '',
          lastName: data.data.fullName.split(',')[0] ?? '',
          balance: data.data.amountDue,
          amount: data.data.amountDue,
          license: data.data.licenseNumber,
          statusId: +data.data.statusId,
          legalCanPay: data.data.legalCanPay,
        }
      : undefined,
  }
}
