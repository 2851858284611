import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { asyncWithConfigProvider } from '@kikoda/generated-config-hooks';

import { QueryClientProvider, QueryClient } from 'react-query';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { theme } from 'components/Foundation/Theme';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const asyncRender = async () => {
  const ConfigProvider = await asyncWithConfigProvider({ host: '' });
  const queryClient = new QueryClient();
  const root = createRoot(document.getElementById("root")!);
  root.render(
    <React.StrictMode>
        <Router>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={createTheme(theme)}>
              <ConfigProvider> 
                <App />
              </ConfigProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </Router>
    </React.StrictMode>,
  );
}

asyncRender();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
