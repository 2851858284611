import { Box } from "@mui/material";
import { Footer } from "components/Foundation/Footer";
import { Header } from "components/Foundation/Header";

export interface PageWrapperProps {
  children: React.ReactNode;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <Box
      display={"flex"}
      minHeight={"100vh"}
      justifyContent={"space-between"}
      flexDirection={"column"}
    >
      <Header />
      <Box
        sx={{
          marginBottom: "auto",
          marginTop: 5,
          paddingX: 15,
          paddingBottom: 15,
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};
