import { Divider, Grid, SxProps, Theme, Typography } from "@mui/material";
import { Button } from "components/Elements/Buttons/Button";
import { IconButton } from "components/Elements/Buttons/IconButton";
import { NicaIcon } from "components/Elements/Buttons/NicaIcon";
import { theme } from "./Theme";

export interface HeaderProps {
  sx?: SxProps<Theme>;
}

export const Header: React.FC<HeaderProps> = ({ sx }) => {
  return (
    <Grid
      container
      sx={{
        ...sx,
        height: 140,
        justifyContent: "center",
        boxShadow: "0px 4px 5px -2px grey",
      }}
    >
      <Grid item alignSelf="center">
        <IconButton
          disableRipple
          sx={{ marginRight: 5 }}
          href="https://nica.com/"
        >
          <NicaIcon viewBox="0 0 317.3 87.9" sx={{ height: 90, width: 320 }} />
        </IconButton>
      </Grid>
      <Grid item container xs={6} alignSelf="center">
        <Grid item marginRight={"auto"}>
          <Typography color={theme.palette.info.main} variant="body1">
            The Florida Birth-Related Neurological Injury Compensation
            Association
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disableRipple
            color="info"
            variant="text"
            size="small"
            href="https://nica.com/news"
          >
            News
          </Button>
        </Grid>
        <Grid item>
          <Button
            disableRipple
            color="info"
            variant="text"
            size="small"
            href="https://nica.com/contact/"
          >
            Contact
          </Button>
        </Grid>

        <Divider
          sx={{
            width: "100%",
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
            marginY: 1,
          }}
        />

        <Grid item marginRight={"auto"}>
          <Typography color={theme.palette.info.main} variant="body1">
            Provider Payment Portal
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disableRipple
            color="info"
            variant="text"
            size="small"
            href="https://nica.com/"
          >
            Back to NICA.COM
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
