export const queryKeys = {
  all: () => ['search'] as const,
  physicians: (criteria: any) =>
    [...queryKeys.all(), 'physician-results', criteria] as const,
  providerById: (id: number) => [...queryKeys.all(), id] as const,
  paymentPageToken: (items: any) => [...queryKeys.all(), 'payment-page-token', items] as const,
}

export const routes = {
  search: '/physicians',
  lookup: '/physician-lookup',
  paymentPageToken: '/payment/page',
}
