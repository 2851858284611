import { Add, Check } from '@mui/icons-material';
import {
  Chip,
  Grid,
  ListItemButton,
  ListItemIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PhysicianResult } from 'components/Modules/DataDisplay/PhysicianSearchGrid';

export interface PhysicianSelectOptionProps {
  option: PhysicianResult;
  addedToGrid?: boolean;
  zeroBalance?: boolean;
  legalStatus?: boolean;
  pendingPaymentStatus?: string;
  onClick: (option: PhysicianResult) => void;
}
export const PhysicianSelectOption: React.FC<PhysicianSelectOptionProps> = ({
  option,
  onClick,
  addedToGrid,
  zeroBalance,
  legalStatus,
  pendingPaymentStatus,
}) => {
  const theme = useTheme();
  const breakpointMatch = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <ListItemButton
      disabled={addedToGrid || zeroBalance || legalStatus || pendingPaymentStatus === 'PAID_IN_FULL'}
      divider
      selected={addedToGrid}
      onClick={() => onClick(option)}
    >
      <Grid container item alignItems={'center'} padding={1}>
        <Grid item xs={1} display="flex">
          <ListItemIcon>{!addedToGrid ? <Add /> : <Check color="primary" />}</ListItemIcon>
        </Grid>
        <Grid item xs={3}>
          <Typography> {option.license}</Typography>
        </Grid>
        <Grid item xs={4} md={3}>
          <Typography>{`${option.firstName} `}</Typography>
        </Grid>

        <Grid item xs={4} md={3}>
          <Typography>{`${option.lastName} `}</Typography>
        </Grid>
        <Grid item xs={2} display={breakpointMatch ? 'none' : 'initial'}>
          {addedToGrid && <Chip variant="outlined" label="Selected" color="primary" />}
          {!zeroBalance && pendingPaymentStatus === 'PAID_IN_FULL' && <Chip variant="outlined" label="Pending Payment(s)" color="default" />}
          {zeroBalance && <Chip variant="outlined" label="No balance due" color="default" />}
          {legalStatus && !zeroBalance && (
            <Chip variant="outlined" label="Physician is in legal status" color="default" />
          )}
        </Grid>
      </Grid>
    </ListItemButton>
  );
};
