import {
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
} from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'

const CustomDataGrid = styled(MuiDataGrid)``

export interface DataGridProps extends MuiDataGridProps {}

export const DataGrid: React.FC<DataGridProps> = (props) => {
  return <CustomDataGrid {...props} />
}
