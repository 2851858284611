import { useQuery } from 'react-query'
import { queryKeys } from './constants'
import { getPaymentPageToken } from '@kikodacode/cares-api'
import { axiosInstance } from './axiosConfig'

export interface usePaymentPageTokenProps {
  items: Parameters<typeof getPaymentPageToken>[0]
}

export const usePaymentPageToken = ({ items }: usePaymentPageTokenProps) => {
  const { data, isLoading } = useQuery(queryKeys.paymentPageToken(items),
  async () => {
    return (await getPaymentPageToken(
      items,
      axiosInstance
      )).data;
    },
    {
      enabled: items.length > 0,
    }
  )
  // Return the provider found
  return { data, isLoading }
}
