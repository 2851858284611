import React, { MouseEventHandler } from 'react';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(MuiButton)``;

export interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  size: 'small' | 'medium' | 'large';
  variant: 'contained' | 'outlined' | 'text';
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  sx?: any;
  disableRipple?: boolean;
  href?: string;
}

/**
 * A button with a colored ripple effect.
 * @returns 
 */
export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <CustomButton {...props}>{children}</CustomButton>;
};
