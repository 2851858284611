import { IconButton as MuiIconButton, styled } from '@mui/material';
import { MouseEventHandler } from 'react';

const CustomButton = styled(MuiIconButton)``;

export interface IconButtonProps {
  sx?: any;
  size?: 'large' | 'medium' | 'small';
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disableRipple?: boolean;
  href?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({ children, ...props }) => {
  return <CustomButton {...props}>{children}</CustomButton>;
};
